// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle }) => (
<div className="footer" style={{
color: 'white' ,
backgroundColor: 'grey',
padding: 20,
fontSize: 15,
// width: '100vmax',
textAlign: "center",
borderTop: "solid rgb(75,75,75)"

}}>
© {new Date().getFullYear()}, Millsap Small Engine Repair 
<br/>
Address: 2220 Old Millsap Hwy, Mineral Wells, TX 76067
<br/>
{/* <a href="https://www.gatsbyjs.org">Gatsby</a> */}
Phone: <a href="tel:9406824724">940-682-4724</a>
<br/>
Site by: <a href="http://kalaelam.com">Kala Elam</a>
</div>
)

Footer.propTypes = {
    siteTitle: PropTypes.string,
  }
  
  Footer.defaultProps = {
    siteTitle: ``,
  }
  
  export default Footer