import React from 'react';
import Link from 'gatsby-link'

 const Menu = () => (
  
        <div style={{
            // background: '#f4f4f4',
            // paddingTop: '10px',
        }}>
            <ul style={{
                listStyle: 'none',
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: 6,
                // textAlign: 'right',
               
            }}>
                <li className="menuLink"><Link to="/">Home </Link></li>
                <li className="menuLink"><Link to="/services"> Services </Link></li>
                <li className="menuLink"><Link to="/contact"> Contact </Link></li>
                {/* <li><Link to="/blog">Blog</Link></li> */}
            </ul>
        </div>
  
 )

export default Menu;
