import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Menu from "../components/menu"

const Header = ({ siteTitle }) => (
<div className="overlay">
  <div className="header"
    style={{
      marginBottom: `0`,
      // width: '100vh',
      // height: '80vmin',
      // display: 'flex',
      // justifyContent: 'center',
      // alignItems: 'center',
    }}
  >
    <div className="h1GoesHere">
      <h1 style={{ margin: 0 }} >
        <Link to="/" className="smH1">
          {siteTitle}
        </Link>
      </h1>
    </div>
    <div className="menu">
    <Menu />
    </div>
  </div>
  </div> 
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
